:root {
    --main-color: rgba(249, 171, 0, 1);
    --light-color: rgba(254, 238, 204, 1);
    --light-grey: rgba(0, 0, 0, 0.1);
}

.Content{
    box-sizing: border-box;
    padding-top: 80px;
    width: 100%;
    height: 100%;
}

a {
    color: black;
    text-decoration: none;
}

a:visited {
    text-decoration: none;
    color: black;
}