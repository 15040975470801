.SideBar{
    /* background-color: white; */
    position: fixed;
    z-index: 100;
    height: 100%;
    width: 15%;

    /* border: blue solid 1px; */
}

.Tab, .CurrentTab{
    box-sizing: border-box;
    height: 70px;
    padding-left: 40px;
    display: flex;
    align-items: center;

    font-size: 16px;
    font-family: Roboto;
    font-weight: 300;
}

.Tab:hover, .Tab:active{
    color: var(--main-color);
    cursor: pointer;
}

.CurrentTab {
    color: var(--main-color);
    opacity: 0.5;
}


@media(max-width: 800px){
    .SideBar{
        position: relative;
        width: 100%;
        padding-top: 0px;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .Tab, .CurrentTab{
        font-size: 13px;
        width: 120px;
        height: 50px;
        padding-left: 0px;
        
        display: flex;
        justify-content: center;
        text-align: center;
        margin: 5px;
        padding: 10px;
        border-radius: 25px;
    }

    .Tab {
        background-color: var(--light-grey) ;
    }

    .Tab:hover {
        background-color: var(--main-color) ;
        color: white;
        opacity: 1;
    }

    .CurrentTab {
        background-color: var(--main-color) ;
        color: white;
        opacity: 1;
    }
}

/* @media(max-width: 800px){
    .SideBar{
        position: relative;
        width: 100%;
        padding-top: 0px;

        display: flex;
        justify-content: center;
    }

    .Tab, .CurrentTab{
        font-size: 13px;
        width: 50px;
        height: 50px;
        padding-left: 0px;
        
        display: flex;
        justify-content: center;
        text-align: center;
        margin: 10px;
        padding: 10px;
        border-radius: 25px;
    }

    .Tab {
        background-color: var(--light-grey) ;
        transition: width 0.2s ease-in 0s;
        
    }

    .Tab:hover {
        width: 120px;
        transform-origin: left;
        background-color: var(--main-color) ;
        color: white;
        opacity: 1;
    }

    .CurrentTab {
        background-color: var(--main-color) ;
        color: white;
        opacity: 0.7;
    } */
/* } */