.UpButton {
    position: fixed;
    width: 60px;
    height: 60px;
    background-color: var(--main-color);
    z-index: 201;
    border-radius: 50%;
    top: auto;
    bottom: 30px;
    right: 30px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 30px;
    color: white;
}

.UpButton:hover {
    cursor: pointer;
    opacity: 0.8;
    transform: scale(1.02);
}
.UpButton:active{
    transform: scale(0.98);
}
.UpButton:focus{
    outline: none;
}

@media (max-width: 800px){
    .UpButton {
        width: 50px;
        height: 50px;
        font-size: 20px;
        bottom: 20px;
        right: 20px;
    }
}