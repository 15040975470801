.PastExp {
    /* border: #FC8275 solid 1px; */
    width: 100%;
    height: fit-content;
    padding: 40px 0px;

    display: block;
}
.Title {
    width: 100%;
    padding: 0px;
    margin: 0px;
    margin-bottom: 80px;
    height: 40pt;

    font-size: 38pt;
    font-family: "Playfair Display";
    font-weight: bold;
    line-height: 1.5cm;
    text-align: center;
}

.TimeLine {
    width: 90%;
    height: fit-content;
    /* background-color: beige; */
    
    display: grid;
    grid-template-columns: 1fr 20px 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;

    margin: 0 auto;
}

.TimeAxis {
    width: 1.5px;
    /* background-color: #FC8275; */
    background-color: var(--main-color);
    grid-column: 2;
    grid-row: 1 / span 4;
    margin: 0 auto;
}

.TimePoint1, .TimePoint2, .TimePoint3, .TimePoint4, .TimePoint5{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: var(--main-color);
    grid-column: 2;
}

.TimePoint1{
    grid-row: 1;
}
.TimePoint2{
    grid-row: 2;
}
.TimePoint3{
    grid-row: 3;
}
.TimePoint4{
    grid-row: 4;
}
.TimePoint5{
    grid-row: 5;
}

.Site1, .Site2, .Site3, .Site4, .Site5{
    height: 100%;
    /* background-color: beige; */
}

.Site1{
    grid-row: 1;
    grid-column: 3;
    margin: 0px 10vw 0 40px;
}
.Site2{
    grid-row: 2;
    grid-column: 1;
    float: right;
    direction: rtl;
    margin: 0px 40px 0 10vw;
}
.Site3{
    grid-row: 3;
    grid-column: 3;
    margin: 0px 10vw 0 40px;
}
.Site4{
    grid-row: 4;
    grid-column: 1;
    direction: rtl;
    margin: 0px 40px 0 10vw;
}
.Site5{
    grid-row: 5;
    grid-column: 3;
    margin: 0px 10vw 0 40px;
}

h6 {
    color: var(--main-color);
    /* color: #FC8275; */
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.5px;
    margin: 0;
}

h4 {
    font-family: 'PlayFair Display Bold';
    font-size: 20pt;
    margin: 20px 0;
}
h5 {
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1.5px;
    margin: 0;
}
p {
    font-family: Roboto;
    font-weight: 300;
    font-size: 14px;
    line-height: 0.75cm;
    letter-spacing: 1.5px;
    margin: 5px 0;
    text-align: justify;
}

@media (max-width: 800px){
    .Title{
        width: 80%;
        padding: 0px;
        margin: 0 auto;
        margin-bottom: 60px;
        height: fit-content;
        font-size: 30pt;
    }
    .TimeLine {
        width: 70%;

        grid-template-columns: 20px 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    
        margin: 0 auto;
    }
    
    .TimeAxis {
        grid-column: 1;
    }

    .TimePoint1, .TimePoint2, .TimePoint3, .TimePoint4, .TimePoint5{
        grid-column: 1;
    }

    .Site1, .Site2, .Site3, .Site4, .Site5 {
        grid-column: 2;
        margin: 0px 0px 40px 40px;
        direction: ltr;
    }
}