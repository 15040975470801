.Intro {
    /* border: #FC8275 solid 1px; */
    position: relative;
    width: 100%;
    height: 500px;
    padding: 40px 0px;

    display: flex;
}

.Portrait {
    width: 50%;
    height: 100%;
    padding-left: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.PortraitPhoto {
    /* background-color: #FC8275; */
    /* background-color: var(--main-color); */
    background-image: url('../../../../assets/portrait.JPG');
    background-repeat: no-repeat;
    background-size: 180%;
    background-position-y: 100%;
    background-position-x: 30%;

    width: 400px;
    height: 400px;
    border-radius: 50%;
}

.Introduction{
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: grey; */
    width: 50%;
    height: 100%;
    padding-right: 5vw;
}

.IntroductionContainer {

    display: block;
    /* justify-content: center;
    align-items: center; */
}

.Name {
    font-size: 38pt;
    padding-left: 40px;
    font-family: "Playfair Display";
    font-weight: bold;
    line-height: 1.5cm;
}

.IntroductionText {
    font-size: 14px;
    font-family: Roboto;
    font-weight: 300;
    line-height: 0.75cm;
    padding-left: 40px;
    margin: 40px 0px ;
    text-align: justify;
    max-width: 500px;
}

.DownloadButton{
    background-color: var(--main-color);
    width: 250px;
    height: 60px;
    border-radius: 30px;
    border: none;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: Roboto;
    font-weight: 300;
    letter-spacing: 1.5px;
    float: left;
    margin-left: 40px;
    padding: 0px;

    transition: all ease 0.2s;
}
.DownloadButton:hover {
    cursor: pointer;
    opacity: 0.8;
    transform: scale(1.02);
}
.DownloadButton:active{
    transform: scale(0.98);
}
.DownloadButton:focus{
    outline: none;
}

@media (max-width: 800px){
    .Intro {
        display: block;
        height: fit-content;
    }
    .Portrait {
        width: 100%;
        height: 350px;
        padding: 0px;
    }
    .PortraitPhoto {
        background-color: #FC8275;
        width: 300px;
        height: 300px;
        border-radius: 50%;
    }
    .Introduction{
        display: flex;
        align-items: center;
        /* background-color: grey; */
        width: 100%;
        height: 100%;
        padding: 0px;
    }
    .Name {
        text-align: center;
        padding: 0px 40px;
        font-size: 30pt;
    }
    .IntroductionText{
        text-align: justify;
        padding: 0px 40px;
        font-size: 16px;
    }
    .DownloadButton{
        float: none;
        margin: 0 auto;
        /* margin-right: 40px; */
    }
    
}