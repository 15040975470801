.Expertise{
    /* border: #FC8275 solid 1px; */
    
    width: 100%;
    height: 500px;
    padding: 40px 0px;

    display: block;
}

.Title {
    width: 100%;
    padding: 0px;
    margin: 0px;
    margin-bottom: 80px;
    height: 40pt;

    font-size: 38pt;
    font-family: "Playfair Display";
    font-weight: bold;
    line-height: 1.5cm;
    text-align: center;
}
.Areas {
    margin: 0px 40px;
    justify-content: space-around;
    display: flex;
    width: 90%;
    height: 300px;

}
.TwoD, .ThreeD, .Coding{
    width: 30%;
    max-width: 300px;
    height: 100%;
    /* border: black solid 1px; */

    background-position: right center;
    background-repeat: no-repeat;
}

.TwoD {
    background-image: url('../../../../assets/home-icon-set-2/One.png');
    background-size: 30%;
}
.ThreeD {
    background-image: url('../../../../assets/home-icon-set-2/Two.png');
    background-size: 40%;
}
.Coding {
    background-image: url('../../../../assets/home-icon-set-2/Three.png');
    background-size: 35%;
}


.Areas h4{
    font-family: 'PlayFair Display Bold';
    font-size: 20pt;
}

.Areas p{
    font-family: Roboto;
    font-weight: 300;
    font-size: 12pt;
    font-style: italic;
    text-align: right;

    margin: 5px 0px;
}

.Areas h1{
    font-family: 'PlayFair Display Bold';
    font-size: 200pt;
    position: absolute;
    margin: 0px 0px;
}

.TwoDIcon, .ThreeDIcon, .CodingIcon {
    width: 75px;
    height: 75px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
}

.TwoDIcon {
    background-image: url('../../../../assets/home-icon-set-2/2DIcon.png');
    background-size: 90%;
}
.ThreeDIcon {
    background-image: url('../../../../assets/home-icon-set-2/3DIcon.png');
}
.CodingIcon {
    background-image: url('../../../../assets/home-icon-set-2/CodingIcon.png');
}

.Sentence{
    font-family: 'PlayFair Display Bold';
    font-size: 20pt;
    text-align: right;
    padding-right: 5vw;
    color: var(--main-color);
}

@media (max-width: 800px){
    .Title{
        width: 80%;
        padding: 0px;
        margin: 0 auto;
        margin-bottom: 60px;
        height: fit-content;
        font-size: 30pt;
    }
    .Expertise{
        height: fit-content;
    }
    .Areas {
        display: block;  
        width: 90%;
        height: fit-content;
        margin: 0 auto; 
    }
    .TwoD, .ThreeD, .Coding{
        margin: 0 auto; 
        width: 100%;
        height: 300px;
    }
    .Sentence{
        margin: 0px;
        margin-top: 20px;
        text-align: center;
        padding: 0;
    }
}
