.Banner{
    /* border: #FC8275 solid 1px;  */
    width: 100%;
    height: 500px;
    padding: 40px 0px;
    display: block;
}

.Slides{
    width: 60%;
    max-width: 1000px;
    height: 500px;

    float: right;
    /* background-image: url('../../../../assets/banner-slides.gif');
    background-size: cover; */
}

.Slide{
    width: 100%;
    max-width: 1000px;
    height: 500px;

    float: right;
    background-image: url('../../../../assets/banner-slides.gif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.Quote{
    /* background-color: #FAE2D6; */
    background-color: var(--light-color);
    width: 55%;
    height: 200px;
    top: 200px;
    position: absolute;
    display: flex;
    align-items: center;
}

.QuoteText{
    padding: 40px 0px;
    font-size: 3.5vw;
    padding: 0px 40px;
    font-family: "Playfair Display";
    font-weight: bold;
    line-height: 1.5cm;
    white-space: pre-line;
}

.ShowAll{
    background-color: var(--light-color);
    width: 20%;
    min-width: 200px;
    max-width: 250px;
    height: 75px;
    top: 500px;
    right: 0;
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: Roboto;
    font-weight: 300;

    transition: all ease 0.2s;
}

.ShowAll:hover {
    background-color: var(--main-color);
    color: white;
    fill: white;
    cursor: pointer;
}
.ShowAll:active {
    transform: translateX(3px)
}

.ShowAll p{
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}




@media (max-width: 800px){
    .Banner{
        width: 100%;
        height: fit-content;
        padding-top: 0;
        /* display: flexbox; */
    }
    .Slides, .Slide{
        width: 100%;
        height: 300px;
        background-size: cover;
    }
    .Quote{
        background-color: white;
        width: 100%;
        position: relative;
        top: 0;
    
        display: flex;
        justify-content: center;
        /* text-align: center; */
        padding: 0px;

    }
    .QuoteText{
        padding: 0px 40px;
        font-size: 25pt;
        line-height: 1.2cm;
        text-align: center;
        white-space: pre-line;
        padding: 0 30px;;
        /* text-align: justify; */
    }

    .ShowAll{
        background-color: var(--main-color);
        color: white;
        height: 60px;
        width: 250px;
        position: relative;
        top: 0;
        border-radius: 30px;
    
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-family: Roboto;
        font-weight: 300;
        margin: 0 auto;
    }

    .ShowAll p {
        color: white;
    }

    .ShowAll:active {
        transform: scale(0.98);
    }

    .ShowAll svg {
        display: none;
    }
}

@media (max-width: 480px){
    .QuoteText{
        font-size: 7vw;
        line-height: 10vw;
    }
}
