/* .Contact{
    background-color: var(--light-color);
    
    width: 100%;
    height: fit-content;
    padding: 40px 0px;

    display: flex;
    position: relative;
    align-items: center;
}
.Details{
    width: 50%;
    margin-left: 10vw;
    padding-right: 40px;
}
.Title {
    width: 100%;
    padding: 0px;
    margin: 0px;
    margin-bottom: 60px;
    height: 40pt;

    font-size: 38pt;
    font-family: "Playfair Display";
    font-weight: bold;
    line-height: 1.5cm;
    text-align: left;
}


.Form{
    box-sizing: border-box;
    height: 100%;
    width: 50%;
    padding-right: 10vw;
    display: flex;
    justify-content: right;
    align-items: center;
}

.Form form{
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    max-width: 500px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr 1fr 3fr;
}

.NameInput, .PhoneInput, .EmailInput, .MessageInput {
    box-sizing: border-box;
    background-color: white;
    border: none;
    height: 40px;
    border-radius: 15px;
    margin: 5px;

    color: var(--main-color);
    font-family: Roboto;
    font-weight: 300;
    font-size: 18px;

    padding-left: 20px;
}

.NameInput:focus, .PhoneInput:focus, .EmailInput:focus, .MessageInput:focus {
    outline: none;
    border: var(--main-color) 1px solid;
}

.NameInput::placeholder, .PhoneInput::placeholder, .EmailInput::placeholder, .MessageInput::placeholder {
    color: var(--main-color);
    opacity: 0.5;
}

.NameInput {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
}

.PhoneInput {
    grid-row: 1 / span 1;
    grid-column: 2 / span 1;
}

.EmailInput{
    grid-row: 2 / span 1;
    grid-column: 1 / span 2;
}

.MessageInput{
    height: 90%;
    grid-row: 3 / span 1;
    grid-column: 1 / span 2;

    padding-top: 15px;
}

.SubmitButton {
    grid-row: 4 / span 1;
    grid-column: 1 / span 2;

    border-radius: 20px;
    background-color: var(--main-color);
    margin: 5px;
    height: 60px;
    border-radius: 30px;
    border: none;

    color: white;
    font-size: 18px;
    font-family: Roboto;
    font-weight: 300;
    letter-spacing: 1.5px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all ease 0.2s;
}
.SubmitButton:hover {
    cursor: pointer;
    opacity: 0.8;
}
.SubmitButton:active{
    transform: scale(0.98);
}
.SubmitButton:focus{
    outline: none;
}

@media (max-width: 800px){
    .Title{
        width: 100%;
        padding: 0px;
        margin: 0 auto;
        margin-bottom: 60px;
        height: fit-content;
        font-size: 30pt;
        text-align: center;
    }

    .Contact{   
        display: block;
    }

    .Details {
        box-sizing: border-box;
        width: 100%;
        margin: 0;
        padding: 0 40px;
        text-align: center;
        margin-bottom: 60px;
    }

    .Details p{
        text-align: center;
    }

    .Form {
        display: block;
        margin: 0 auto;
        padding: 0;
        width: 80%;
    }

    form{
        width: fit-content;
        margin: 0 auto;
    }

    .NameInput, .PhoneInput, .EmailInput, .MessageInput {
        font-size: 16px;
    }

    p {
        font-size: 16px;
    }
} */

.Contact{
    background-color: var(--light-color);
    
    width: 100%;
    height: fit-content;
    padding: 60px 0px;

    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
}
.Title {
    width: 100%;
    padding: 0px;
    margin: 0px;
    margin-bottom: 60px;
    height: 40pt;

    font-size: 38pt;
    font-family: "Playfair Display";
    font-weight: bold;
    line-height: 1.5cm;
    text-align: center;
}
.Details {
    width: 60%;
    display: flex;
    justify-content: space-around;
}
.Info {
    width: 33%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}
.Info p, .Info a, .Info a:visited {
    font-family: Roboto;
    font-weight: 300;
    font-size: 14px;
    line-height: 0.75cm;
    letter-spacing: 1.5px;
    margin: 5px 0;

    text-align: center;
    line-height: 0.5cm;
}
.Info a:hover {
    transition: 0.2s ease;
    font-size: 14.5px;
    cursor: pointer;
    color: var(--main-color);
}
.Info a:active {
    font-size: 13.5px;
    color: var(--main-color);
}

.Details svg{
    font-size: 30px;
    margin-bottom: 15px;
    color: var(--main-color);
}

@media (max-width: 800px){
    .Details {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    .Info {
        margin: 15px;
        width: 100%;
    }
}