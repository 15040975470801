.App{
    width: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0px 0px;
    /* background-color: lightgray; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: relative;
}