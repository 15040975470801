.NavBar {
    font-family: Roboto;
    font-weight: 300;
    position: fixed;
    width: 100%;
    height: 100px;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 200;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Logo {
    width: 50px;
    height: 50px;
    background-image: url('../../assets/logo.png');
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 40px;
}

.Buttons{
    width: 70%;
    max-width:600px;
    height: 30px;
    /* background-color: grey; */
    display: flex;
    margin-right: 20px;
}

.Button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 0.5px black solid;
    height: 100%;
    width: 25%;
    text-decoration: none;
    color: black;
    transition: all ease 0.2s;
}

.Button:last-child {
    border-right: none;
}

.Button:hover {
    font-size: 17px;
}
.Button:active {
    font-size: 15px;
}

.Button:hover, .Button:active, .ClickedButton {
    cursor: pointer;
    color: var(--main-color);
    /* border-right: 0.5px var(--main-color) solid; */
}

@media (max-width: 800px){
    .NavBar {
        height: 80px;
    }
    .Logo {
        width: 40px;
        height: 40px;
        margin-left: 20px;
    }
    .Buttons{
        width: 80%;
        margin-right: 5px;
    }
    .Button:hover, .ClickedButton {
        color: black;
    }
}

@media (max-width: 400px){
    .Logo {
        display: none;
    }
    .NavBar {
        justify-content: center;
    }
    .Buttons {
        width: 90%;
    }
}