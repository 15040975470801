.Projects{
    width: 100%;
    height: fit-content;
    box-sizing: border-box;

    padding: 40px 0px;
}

.ImgV, .ImgS, .ImgH, .ImgL {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.Text{
    font-family: Roboto;
    font-weight: 300;
    font-size: 18px;
    text-align: justify;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 5vw;
    line-height: 0.8cm;
}

.Title{
    text-align: left;
    font-family: Roboto;
    font-weight: 500;
    font-size: 19px;
}
.Projects video{
    height: 800px;
}
.Projects .SmallVid{
    height: 100%;
}

@media (max-width: 1200px){
    .ImgV{
        width: 100%;
        padding-bottom: 175%;
        /* height: 200vmin; */
    }
    .ImgH{
        width: 100%;
        padding-bottom: 65%;
        /* height: 75vmin; */
    }
    .ImgS{
        width: 100%;
        padding-bottom: 100%;
        /* height: 100vmin;   */
    }
    .ImgL{
        width: 100%;
        padding-bottom: 40%;
        /* height: 100vmin;   */
    }

    .Text{
        font-size: 14px;
        padding: 6vw;
    }
    .Title{
        font-size: 15px;
    }
    .Projects video{
        height: 100%;
    }
}

@media (min-width: 800px) and (max-width: 1200px){
    .Text{
        font-size: 16px;
        /* padding: 75px 50px; */
    }
    .Title{
        font-size: 17px;
    }
}

@media (max-width: 800px){
    .Text{
        padding: 10vw;
    }
    .Projects{
        padding: 0px;
    }
}