
.Elaboration{
    width: 100%;
    height: fit-content;
    
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media (max-width: 1200px){
    .Elaboration{
        display: block;
        /* min-width: 500px; */
    }
}
