.Works{
    /* border: #FC8275 solid 1px; */
    
    width: 100%;
    height: fit-content;
    padding: 40px 0px;

    display: block;
    position: relative;
}

.Title {
    width: 100%;
    padding: 0px;
    margin: 0px;
    margin-bottom: 80px;
    height: 40pt;

    font-size: 38pt;
    font-family: "Playfair Display";
    font-weight: bold;
    line-height: 1.5cm;
    text-align: center;
}
.Areas {
    margin: 0px 10vw;
    height: fit-content;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.Product, .Service, .UIUX, .Visual{
    width: fit-content;
    height: fit-content;
    background-position: right center;
    background-repeat: no-repeat;

    display: block;
    margin: 0 auto;
}

.Label {
    margin: 1cm 0;
    display: flex;
}

.Areas h4{
    font-family: 'PlayFair Display Bold';
    font-size: 20pt;
    margin: 0;
}


.Areas p{
    font-family: Roboto;
    font-weight: 300;
    font-size: 12pt;
    font-style: italic;
    margin: auto 10px;
}



.ProductImg, .ServiceImg, .UIUXImg, .VisualImg {
    width: 22vw;
    min-width: 250px;
    height: 22vw;
    min-height: 250px;
    background-color: beige;
    /* box-shadow: #C9E3D8 0.3cm 0.3cm; */
    box-shadow: var(--light-color) 0.3cm 0.3cm;
    background-repeat: no-repeat;
    background-size: cover;

    transition: all 0.2s ease;
}

.ProductImg:hover, .ServiceImg:hover, .UIUXImg:hover, .VisualImg:hover {
    box-shadow: var(--main-color) 0.3cm 0.3cm;
    cursor: pointer;
    transform: scale(1.01);
}

.ProductImg:active, .ServiceImg:active, .UIUXImg:active, .VisualImg:active {
    transform: translateX(0.2cm) translateY(0.2cm);
    box-shadow: var(--main-color) 0.1cm 0.1cm;
    cursor: pointer;
}

.ProductImg {
    background-image: url('../../../../assets/product/lexigon-hero.jpg');
    background-position: center;
}
.ServiceImg {
    background-image: url('../../../../assets/service/smile-hero.jpg');
    background-position: left;
}
.UIUXImg {
    background-image: url('../../../../assets/uiux/musee-hero.jpg');
    background-position: left;
}
.VisualImg {
    background-image: url('../../../../assets/visual/aural-hero.jpg');
    background-position: center;
}


@media (max-width: 800px){
    .Title{
        width: 80%;
        padding: 0px;
        margin: 0 auto;
        margin-bottom: 60px;
        height: fit-content;
        font-size: 30pt;
    }
    .Areas {
        display: block;  
        width: 90%;
        height: fit-content;
        margin: 0 auto; 
    }
}
