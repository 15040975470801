.WorkBoard{
    height: fit-content;
    width: 100%;
    box-sizing: border-box;
    padding-right: 40px;
    padding-top: 40px;
    grid-column: 2 / span 2;
    /* border: blue solid 1px; */

    display: grid;
    grid-template-columns: 1fr 40px 1fr;
}

.Column1{
    height: 100%;
    width: 100%;
    /* border: red solid 1px; */
}

.Column2{
    height: 100%;
    width: 100%;
    /* border: red solid 1px; */

    grid-column: 3/span 1;

    padding-top: 130px;
}

@media(max-width: 1060px){
    .WorkBoard{
        display: block;
    }

    .Column2{
        padding-top: 0px;
    }
}

@media(max-width: 800px){
    .WorkBoard{
        padding-right: 0px;
        padding-top: 40px;
    }
}