.Work{
    width: 100%;
    max-width: 400px;
    height: 400px;

    margin-bottom: 40px;

}

.Image{
    width: 95%;
    height: 300px;
    /* background-color: grey; */
    box-shadow: var(--light-color) 0.3cm 0.3cm;

    margin-bottom: 40px;
    background-size: cover;
    background-position: center;

    transition: all 0.2s ease;
}

.Image:hover {
    box-shadow: var(--main-color) 0.3cm 0.3cm;
    cursor: pointer;
    transform: scale(1.01);
}
.Image:active {
    transform: translateX(0.2cm) translateY(0.2cm);
    box-shadow: var(--main-color) 0.1cm 0.1cm;
    cursor: pointer;
}

.Label{
    display: flex;
    align-items: flex-end;
}
.Title{
    font-family: "Playfair Display";
    font-weight: bold;
    font-size: 30px;
    margin-right: 10px;
}
.Tag{
    font-family: Roboto;
    font-weight: 300;
    font-size: 18px;
    font-style: italic;
    line-height: 0.75cm;
}


@media(max-width: 1240px) and (min-width: 1060px){
    .Label{
        display: block;
    }
}


@media(max-width: 1060px){
    .Work{
        margin-left: auto;
        margin-right: auto;
    }
}

@media(max-width: 800px){
    .Image{
        width: 90%;
        margin: 0 auto 40px auto;
    }
    .Label{
        width: 90%;
        margin: 0 auto; 
    }
    .Title{
        font-size: 20px;
    }
    .Tag{
        font-size: 16px;
        line-height: 0.6cm;
    }
}

@media(max-width: 400px){
    .Label{
        display: block;
    }
}