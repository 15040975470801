.Works{
    width: 100%;
    height: fit-content;
    box-sizing: border-box;

    padding: 40px;
    display: grid;
    grid-template-columns: 1fr 2fr;
}

@media(max-width: 800px){
    .Works{
        padding-top: 0;
        padding: 20px;
        display: block;
    }
}