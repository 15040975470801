.SlideShow{
    width: 100%;
    /* height: 800px; */
    
    display: grid;
    grid-template-columns: 8fr 1fr 1fr;
    grid-template-rows: 725px 75px;
}

.Photo{
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.DetailColumn{
    grid-column: 2/span 2;

    text-align: left;
    color: white;

    display: flex;
    flex-wrap: wrap;

    align-items: center;
    align-content: space-between;
    padding: 100px 50px;
}

.DetailBar{
    grid-column: 1;
    display: flex;
    padding: 0 50px;
    align-items: center;
    justify-content: space-between;
}
.DetailBar p{
    margin: 0
}
.DetailBar h5{
    padding-bottom: 5px
}

.Block{
    width: 25%;
}

.Next, .Back{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    cursor: pointer;
}
.Next:hover, .Back:hover{
    opacity: 0.5;
    font-size: 30px;
    transition: all 0.2s ease-in 0s;
}

@media (max-width: 1200px){
    .SlideShow{
        height: fit-content;
        /* min-width: 500px; */
        grid-template-columns: 4fr 1fr 1fr;
        grid-template-rows: 0.8fr 0.6fr 0.15fr;
    }

    .Photo{
        width: 100%;
        grid-column: 1 / span 3;
        padding-bottom: 75%; 
    }

    .DetailColumn{
        /* display: block; */
        grid-column: 1;
        grid-row: 2 / span 2;
    
        /* padding: auto; */
        align-content: center;
    }
    
    .DetailBar{
        grid-column: 2 / span 2;
        grid-row: 2;

        display: flex;
        flex-wrap: wrap;
        align-content: center;

        padding: 20px;
    }

    .DetailBar p, .DetailColumn p{
        font-size: 14px;
        line-height: 0.8cm;
    }
    .DetailBar h5{
        font-size: 14px;
    }

    .Block{
        width: 100%;
        margin: 10px;
    }
}

@media (min-width: 900px) and (max-width: 1200px){
    .DetailBar p, .DetailColumn p{
        font-size: 14px;
    }
    .DetailBar h5{
        font-size: 14px;
    }
    .SlideShow{
        grid-template-rows: 1fr 0.4fr 0.1fr;
    }
}

/* @media (max-width: 600px){
    .SlideShow{
        grid-template-rows: 1fr 0.8fr 0.2fr;
    }
    .DetailColumn {
        align-content: center;
        padding: 7vw;
    }
    .DetailBar{
        padding: 3vw;
    }
    .DetailBar p, .DetailColumn p{
        font-size: 3vw;
        line-height: 5vw;
    }
    .DetailBar h5{
        font-size: 3vw;
    } 
} */

@media (max-width: 600px){
    .SlideShow{
        display: flex;
        flex-wrap: wrap;
    }
    .Next, .Back {
        height: 70px;
        width: 50%;
    }
    .DetailColumn {
        align-content: center;
        padding: 10vw;
    }
    .DetailBar{
        padding: 10vw;
        width: 100%;
    }
    .DetailBar p, .DetailColumn p{
        font-size: 14px;
        line-height: 30px;
    }
    .DetailBar h5{
        font-size: 14px;
    }
}